
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getActiveUserDataList, exportActiveDataUser } from "@/api/request/bigdata";

//组件
@Component({
  name: "UserActiveTime",
  components: {
    Pagination,
  },
})

//函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    row: 30, //每页行数

    //日期数据
    date: "", //查询日期
    create_time: "", //注册日期

    //查询参数
    type: 0, //0 全部 1 全部用户 2 博主 3 男用户 4 女用户
    internal: 0, //是否包含内部账号 0 不包含 1 包含

    //是否导出Excel
    excel: false, //是否导出Excel
  };

  //类型参数
  private typeOptions: any[] = [
    { value: 0, label: "全部账号" },
    { value: 1, label: "全部用户" },
    { value: 2, label: "博主" },
    { value: 3, label: "男用户" },
    { value: 4, label: "女用户" },
  ];

  //内部账号参数
  private internalOptions: any[] = [
    { value: 0, label: "正常账号" },
    { value: 1, label: "内部账号" },
  ];

  //创建
  created() {
    //数据赋值
    this.listQuery.date = DTCls.getCurDateTimeYMD();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;

    //获取数据
    const { data } = await getActiveUserDataList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;

    //获取数据
    const { data } = await exportActiveDataUser(this.listQuery);

    //保存数据
    saveAs(data, "用户活跃时长列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
